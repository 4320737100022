a,a:link,a:visited{
  text-decoration:none;
  color:#86888f;
}


.error{
  label {

    color:darkred !important;

  }
    color:darkred !important;
  ::placeholder{
    color:darkred !important;

  }
  ::-moz-placeholder{
    color:darkred !important;
  }
  ::-webkit-input-placeholder{
    color:darkred !important;
  }

}


.bg-info-appy{
    padding:10px 20px;
  background-color:rgba(#333,0.3);
}

.nb-label{
  font-size:80%;
  font-weight:200;
  color:#999;
}

.smlabel{
  font-size:80%;
  font-weight:200;
  color:#999;
}

.sbbg-transparent{
  background-color:transparent !important;
  --background:transparent;
  &:hover{
    --background:transparent;
    background-color:transparent;
  }
  -background-color:transparent;
}
